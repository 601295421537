<template>
  <div>
    <b-card-group deck>
      <b-card
          :header="'Thông tin bot'"
          header-tag="header"
      >
        <b-form  @submit="saveForm" @reset="onReset" >
          <h2 class="title-block">THÔNG TIN BOT</h2>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <b-form-group
                    id="input-dk-2"
                    label="Tên bot (*)"
                    label-for="ms-title"
                    class="col-md-12"
                >
                  <b-form-input
                      id="ms-title"
                      v-model="form.name"
                      maxlength="100"
                      required
                      placeholder="Nhập tên bot"
                      :class="['col-md-12',{ 'is-invalid': form.errors.has('name') }]"
                  ></b-form-input>
                  <has-error :form="form" field="name"></has-error>
                </b-form-group>
              </div>
                <div class="row">
                    <b-form-group
                        id="input-dk-2"
                        label="SDT (*)"
                        label-for="ms-title"
                        class="col-md-12"
                    >
                        <b-form-input
                            id="ms-title"
                            v-model="form.phone"
                            required
                            placeholder="Nhập số điện thoại Bot"
                            :class="['col-md-12',{ 'is-invalid': form.errors.has('phone') }]"
                        ></b-form-input>
                        <has-error :form="form" field="phone"></has-error>
                    </b-form-group>
                </div>
                <div class="row">
                    <b-form-group
                        id="input-dk-2"
                        label="Point (*)"
                        label-for="ms-title"
                        class="col-md-12"
                    >
                        <b-form-input
                            id="ms-title"
                            :value="form.point"
                            required
                            @keydown="numbersOnly($event)"
                            @change="changePoint"
                            placeholder="Nhập số điểm cho Bot"
                            :class="['col-md-12',{ 'is-invalid': form.errors.has('point') }]"
                        ></b-form-input>
                        <has-error :form="form" field="point"></has-error>
                    </b-form-group>
                </div>
                <div class="row">
                    <b-form-group
                        id="input-group-0"
                        label="Avatar:"
                        label-for="input-0"
                        class="col-md-12"
                    >
                        <div style="display: flex">
                            <div style="height: 38px; margin-right: 20px" v-if="form.avatar && form.avatar.length >0">
                                <img
                                    style="width: 38px; height: 38px"
                                    :src="form.avatar"
                                    alt=""
                                />
                            </div>
                            <b-form-file
                                @change="onImageChange($event)"
                                placeholder="chọn avatar"
                                type="file"
                                accept="image/*"

                            ></b-form-file>
                        </div>

                    </b-form-group>
                </div>
                <div class="row">
                    <div class="form-inline" style="margin-bottom: 20px">
                        <input   id="stt" class="form-control" type="checkbox" :true-value="1" :false-value="0" value="1" v-model="form.status">
                        <label for="stt" style=" margin-left: 10px">
                            Hiển thị
                        </label>
                    </div>
                </div>
            </div>
          </div>

          <div class="">
            <b-button type="button" @click="saveForm" variant="primary">Cập nhập</b-button>
            <router-link :to="'/top-service'">
              <b-button variant="danger">Hủy bỏ</b-button>
            </router-link>
          </div>
        </b-form>
      </b-card>
    </b-card-group>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import { Form } from 'vform';
import {
  HasError,
} from 'vform/src/components/bootstrap5'
Vue.component("multiselect", Multiselect);
Vue.component(HasError.name, HasError)
const WalletRunRepository = RepositoryFactory.get("WalletRun");
import EventRepository from "@/core/repositories/EventRepository";
export default {
  name: "FormMission",
  mixins: [Common],
  components: {
    // eslint-disable-next-line vue/no-unused-components
  },
  data() {
    return {
      form: new Form({
        name: '',
        phone: '',
        avatar: '',
        point: 0,
        status: 1,
      })
    };

  },
  computed: {

  },
  watch: {

  },
    async created() {

        try {
            if (this.$route.params.id) {
                await this.getBot(this.$route.params.id);
            }
        } catch (error) {
            // Xử lý lỗi nếu cần
        }
    },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Chiến dịch đua top ", route: "/top-service/create" },
      { title: "Thông tin bot" },
    ]);

  },

  methods: {
    saveForm () {
        var url = '/top-service/bot/'+this.$route.params.id;
        this.form.submit('post', url)
            .then(({data}) => {
                if (1 === data.error_code) {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: data.message,
                    });
                } else {
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: data.message,
                    });
                    this.$router.push({ name: "top-service" });
                }
            })
            .catch(() => {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Vui lòng kiểm tra lại form nhập liệu.",
                });
            })
    },
    async getBot(id) {
      try {
        let response = await EventRepository.bot(id);
        if (response.data.error_code === 0) {
          let data = response.data.data;
          if(data){
              this.form.name = data.name;
              this.form.phone = data.phone;
              this.form.avatar = data.avatar;
              this.form.point = data.point;
              this.form.status = data.status;
          }
        } else {
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          group: "foo",
          type: "error",
          title: error,
        });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
          name: '',
          phone: '',
          avatar: '',
          point: '',
          status: 1,
      };
    },
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize> 2){
        this.$notify({
          group: "foo",
          type: "error",
          title:"Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia();
      //this.createImage(files[0]);
    },
    uploadMedia: async function () {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              this.form.avatar = response.data.data.link;
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
    changePoint(event){
        if(this.form.point > event){
            this.$notify({
                group: "foo",
                type: "error",
                title: "Số point phải lớn hơn số cũ: "+this.form.point.toString(),
            });
            return false;
        }
        this.form.point = event;
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.title-block{
  color: #FF0000;
  font-size: 18px;
  font-weight: bold;
}
.datetime-picker div input{
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3F4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.mx-datepicker{
  width: 100% !important;
}
.mx-datepicker input{
  height: 39px  !important;
}
</style>